.slick-active {
  // display:none !important
}
.BannerSwiper {
  margin: auto !important;
  max-width: 100% !important;
  // min-height: 436px !important;
  position: relative !important;

  .carousel_container {
    // max-width: 1450px !important;
    .ant-carousel {
      max-width: 100% !important;
      .slick-slider {
        // margin: auto !important;
        max-width: 1366px !important;
        position: relative !important;
        .slick-prev {
          left: -8px !important;
        }
        .slick-prev:before {
          display: none !important;
        }
        .slick-next {
          right: 30px !important;
        }
        .slick-next:before {
          display: none !important;
        }
        .slick-list {
          .slick-track {
            // gap: 10px !important;
            display: flex !important;
            // margin-left: 7px !important;
            // justify-content: space-between !important;
            // .slick-slide {
            // width: auto !important;
            // margin: auto 10px !important;
            // margin-right: 3px !important;
            // margin: auto 5px auto 0px !important;
            // }
          }
        }

        .slick-dots {
          z-index: 1 !important;
          bottom: -15px !important;

          li {
            width: 40px;
            height: 3px;
          }
          button {
            background: #cecece !important;
          }
          button::before {
            display: none !important;
          }
          .slick-active {
            button {
              background: #535353 !important;
            }
          }
        }
      }
    }
  }
}

.slick-dots {
  z-index: 1 !important;
  bottom: -15px !important;

  li {
    width: 40px;
    height: 3px;
  }

  button {
    background: #cecece !important;
  }

  button::before {
    display: none !important;
  }

  .slick-active {
    button {
      background: #535353 !important;
    }
  }
}

// Mz Css
body .slide-group {
  display: flex !important;
}
body .group-item {
  display: flex;
  padding-right: 10px;
}
body .group-item img {
  display: flex;
  height: 100%!important;
  object-fit: cover;
}
body .slide-group .group-item:last-child{
  padding: 0;
}
// .BannerSwiper .carousel_container .ant-carousel .slick-slider .slick-list .slick-track > .slick-current, .BannerSwiper .carousel_container .ant-carousel .slick-slider .slick-list .slick-track > .slick-active {
//   padding-right: 10px;
// }

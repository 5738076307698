@import "../../../styles/Colors.scss";

.ProductsGroupComp {
  .ant-image-mask-info {
    display: none !important;
  }

  .three_imgs_container {
    position: relative;
    .img_mask {
      .ant-image-mask {
        opacity: 80;
      }

      .ImageCount {
        position: relative;
        .img_mask_tag {
          cursor: pointer;
        }
        .img_mask_tag_mobile {
          right: -27px;
          top: -74px;
        }
      }
      .ant-typography {
        position: absolute;
        left: 35%;
        top: 40%;
        color: white;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .sectionHeadingContainer {
    text-align: center;
    padding: 10px 0px 10px 0px !important;

    .sectionHeadingBold {
      text-align: center;
      padding: 10px !important;
      // margin: 10px !important;
      font-size: 25px !important;
      color: $secondaryTwo !important;
      font-family: "Inter-Regular" !important;
      font-weight: 100 !important;
    }

    .sectionHeadingMobileBold {
      text-align: center;
      margin: 10px !important;
      font-size: 16px !important;
      color: $secondaryTwo !important;
      font-family: "Inter-Regular" !important;
      font-weight: 100 !important;
    }

    .sectionHeadingSemiBold {
      text-align: center;
      margin: 10px !important;
      font-size: 24px !important;
      color: $secondaryTwo !important;
      font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
    }
  }
}
.gray_background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 64, 64, 0.7);
  /* Dark gray with 70% opacity */
  z-index: 1;
  height: 133px;
  width: 86px;
}
@media (min-width: 320px) and (max-width: 767px) {
  body .product-group-grid {
    justify-content: unset !important;
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    width: max-content;
    overflow-x: scroll;
  }

  body .product-group-grid>div {
    min-width: 96%;
  }

  body .product-group-grid>div>div {
    justify-content: center !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  body .product-group-grid {
    justify-content: unset !important;
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    width: max-content;
    overflow-x: scroll;
  }
  body .product-group-grid > div {
    min-width: 50%;
  }
  body .product-group-grid > div > div {
    justify-content: center !important;
  }
}

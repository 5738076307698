@media (max-width: 1024px) {
  body .FilterByGenderSection .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #333333 !important;
  }
  body .FilterByGenderSection .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
  .filter-gender-title {
    display: none !important;
  }

  body .FilterByGenderSection .ant-tabs-nav-list {
    // transform: translate(0px, 0px);
    width: 97%;
    margin-left: auto !important;
    margin-right: auto;
    justify-content: space-between;
  }
  body .FilterByGenderSection .ant-tabs-tab-btn {
    padding: 5px 15px 5px 15px !important;
  }
  .ant-tabs-tab {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    justify-content: center !important;
  }
  // Mz css
  body .FilterByGenderSection .ant-tabs-tab .ant-tabs-tab-btn {
    width: max-content !important;
    padding: 5px 15px !important;
    font-size: 14px !important;
    font-family: 'Inter-Regular' !important;
    font-weight: 100;
  }
  body .FilterByGenderSection .ant-tabs-tab {
    width: max-content !important;
  }
}

.FilterByGenderSection {
  .subSectionHeadingBold {
    font-size: 20px !important;
    max-width: 100% !important;
    color: #000000 !important;
    font-family: 'Inter-Medium'!important;
    font-weight: 100;
  }

  .sectionHeadingBold {
    z-index: 1;
    top: 44px;
    left: 10px;
    font-size: 25px;
    font-weight: 700;
    padding: 10px !important;
    color: #1e1e1e;
    position: relative;
    padding: 5px !important;
  }

  .ant-tabs-nav-wrap {
    padding: 10px;
    justify-content: flex-end;
    background-color: #ffffff;

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  .ant-tabs-tab {
    border: none;
    // padding: 5px;
    padding: 0px !important;
    .ant-tabs-tab-btn {
      font-size: 18px;
      color: #333333 !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-size: 18px;
      font-family: 'Inter-Regular' !important;
    }
  }

  .ProductsCarousel {
    max-width: 100% !important;
  }
}

.FilterByGenderSection {
  .by-gender-carousel-mobile {
    padding: 10px 0px;
    .ant-tabs-nav-wrap {
      justify-content: flex-start !important;
      padding: 0px;
      margin: 0px 5px !important;
    }
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      //flex: 0 1 auto !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 !important;
      color: #333333 !important;
      margin: 0px !important;
      // padding: 5px !important;
      font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      .ant-tabs-tab-btn {
        font-size: 16px;
        color: #333333 !important;
      }
    }
    .ant-tabs-tab-active {
      background-color: #333333 !important;

      .ant-tabs-tab-btn {
        color: #ffffff !important;

        border: none !important;
      }
      border: none !important;
    }

    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-card-body {
      // display: none !important;
    }

    // .ant-card-cover {
    //   padding: 0 !important;
    // }
  }
}

.SubCategoryTabs {
  margin: 22px auto !important;

  .tab {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    background: #ffffff;
    border: 4px solid #f2f3f7;
    display: flex;
    align-items: center;
    /* Center the text vertically */
    justify-content: center;
    /* Center the text horizontally */
    overflow: hidden;
    /* Hide overflowing content */

    .ant-typography {
      margin: 0px;
      color: #000000;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* Display ellipsis (...) for overflowed text */
      overflow: hidden;
      /* Hide overflowed text */
      max-width: 100%;
      /* Ensure the text doesn't exceed the card width */
    }
  }

  .view-all-tab {
    padding: 10px 0px;
    cursor: pointer;
    color: #5a7184;
    text-align: center;
    font-size: 12px;
  }
}

.custom-tooltip {
  .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.6) !important;
  }
}
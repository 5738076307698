@import "../../styles/Colors.scss";

.Homepage {
  margin: auto !important;
  overflow: hidden !important;
  min-height: 70vh !important;
  max-width: 1366px !important;

  .CataloguesCarousel {
    .sectionHeadingContainer {
      margin-bottom: 5px !important;
      padding: 20px 0 5px 0 !important;

      .sectionHeadingBold {
        text-align: left;
        // margin: 10px !important;
        font-size: 24px !important;
        // padding: 10px !important;
        font-family: Inter sans-serif !important;
        color: $secondaryTwo !important;
        font-weight: 700 !important;
      }

      .error-404-Desktop {
        text-align: center;
        font-size: 25px !important;
        font-family: "Inter-Regular" !important;
        font-weight: 100 !important;
        color: brown !important;
      }
      .error-not-found-button {
        border-radius: 100px;
        font-size: 13px;
        height: 49px;
        color: white;
        background-color: rgb(255, 31, 109);
        margin-top: 18px;
        padding: 17px 50px;
      }
      .sectionHeadingMobileBold {
        text-align: center;
        margin: 10px !important;
        font-size: 18px !important;
        color: $secondaryTwo !important;
        font-family: Inter sans-serif !important;
        font-weight: 700 !important;
      }

      .error-404-Mobile {
        text-align: center;
        margin: 10px !important;
        font-size: 16px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Regular" !important;
        font-weight: 100 !important;
        color: brown !important;
      }
      .sectionHeadingSemiBold {
        text-align: center;
        margin: 10px !important;
        font-size: 24px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      }
    }
  }

  .ProductsCarousel {
    .sectionHeadingContainer {
      // margin-bottom: 5px !important;

      .sectionHeadingBold {
        padding: 10px !important;
        text-align: center;
        display: block !important;
        font-size: 24px !important;
        font-family: "Inter-Regular" !important;
        color: $secondaryTwo !important;
        font-weight: 100;
      }

      .sectionHeadingMobileBold {
        text-align: center;
        margin: 10px !important;
        font-size: 18px !important;
        color: $secondaryTwo !important;

        font-weight: 100 !important;
      }

      .sectionHeadingSemiBold {
        text-align: center;
        margin: 10px !important;
        font-size: 24px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      }
    }

    .carousel_container {
      margin-top: 16px !important;
      max-width: 100% !important;
      position: relative !important;

      .slick-next {
        .next-btn-container {
          position: absolute;
          // left: -28px;
        }
      }
      .slick-prev {
        .prev-btn-container {
          position: absolute;
          // right: -28px;
        }
      }
    }
  }

  .BannerSection {
    margin-top: 32px !important;
    .sectionHeadingContainer {
      margin-bottom: 5px !important;

      .sectionHeadingBold {
        text-align: center;
        padding: 10px !important;
        // margin: 10px !important;
        font-size: 24px !important;
        font-family: "Inter-Regular" !important;
        color: $secondaryTwo !important;
        font-weight: 100;
      }

      .sectionHeadingMobileBold {
        text-align: center;
        margin: 10px !important;
        font-size: 18px !important;
        color: $secondaryTwo !important;
        font-family: Inter sans-serif !important;
        font-weight: 700 !important;
      }

      .sectionHeadingSemiBold {
        text-align: center;
        margin: 10px !important;
        font-size: 24px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      }
    }
    .carousel_container {
      margin-top: 22px !important;
    }
  }

  .MultiUsableComp {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    span strong {
      font-family: "Inter-Regular" !important;
      font-weight: 100;
    }
    // max-width: 1350px !important;

    .sectionHeadingContainer {
      text-align: center;
      padding: 10px 0px 5px 0px !important;

      .sectionHeadingBold {
        text-align: center;
        padding: 5px !important;
        // margin: 10px !important;
        font-size: 25px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Regular" !important;
        font-weight: 100;
      }

      .sectionHeadingMobileBold {
        text-align: center;
        margin: 10px !important;
        font-size: 18px !important;
        color: $secondaryTwo !important;
        font-family: Inter sans-serif !important;
        font-weight: 700 !important;
      }

      .sectionHeadingSemiBold {
        text-align: center;
        margin: 10px !important;
        font-size: 24px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      }
    }

    button {
      display: none !important;
    }

    .ProductsCarousel {
      max-width: 100% !important;
    }
  }

  .BrandsCollection {
    .sectionHeadingContainer {
      text-align: center;
      padding: 2px 0px 0px 0px !important;

      .sectionHeadingBold {
        text-align: center;
        padding: 10px !important;
        // margin: 10px !important;
        font-size: 25px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Regular" !important;
        font-weight: 100;
      }

      .sectionHeadingMobileBold {
        text-align: center;
        margin: 10px !important;
        font-size: 18px !important;
        color: $secondaryTwo !important;
        font-family: Inter sans-serif !important;
        font-weight: 700 !important;
      }

      .sectionHeadingSemiBold {
        text-align: center;
        margin: 10px !important;
        font-size: 24px !important;
        color: $secondaryTwo !important;
        font-family: "Inter-Medium" !important;
        font-weight: 100 !important;
      }
    }
  }

  // .FilterByGenderSection {
  // }

  // .CategoriesHeader {
  // }
}
.FilterByGenderSection .sectionHeadingBold {
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.SubCategoryTabs span {
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
}
body .main-heading {
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
}
// margin-top: 20px !important;
@media (min-width: 315px) and (max-width: 1023px) {
  .Homepage {
    margin-top: 12px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1100px) {
  .Homepage {
    max-width: 1024 !important;
  }
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .Homepage {
    max-width: 1100 !important;
  }
}
@media (min-width: 1201) and (max-width: 1300px) {
  .Homepage {
    max-width: 1200 !important;
  }
}
@media (min-width: 1301) and (max-width: 1400px) {
  .Homepage {
    max-width: 1300 !important;
  }
}
@media (min-width: 1401) {
  .Homepage {
    max-width: 1366 !important;
  }
}

// Mz Css
.added-footer a,
.added-footer a:hover {
  text-decoration: underline;
}
.footer a {
  text-decoration: underline;
}
@media (min-width: 1025px) {
  body
    .FilterByGenderSection
    .sectionHeadingContainer
    .ant-tabs-tab-active
    > div {
    background: #000;
    color: #fff !important;
  }
  body
    .FilterByGenderSection
    .sectionHeadingContainer
    .ant-tabs-nav-list
    > div
    > div {
    padding: 5px 10px;
  }
  body .FilterByGenderSection .sectionHeadingBold {
    top: 50px;
  }
}
@media (max-width: 1024px) {
  body
    .Homepage
    .CataloguesCarousel
    .sectionHeadingContainer
    .sectionHeadingMobileBold {
    margin-top: 20px !important;
  }
  body .Homepage .MultiUsableComp {
    margin: 0px !important;
    padding: 0px !important;
  }
  body .Homepage .MultiUsableComp .sectionHeadingContainer {
    padding: 16px 0px 10px 0px !important;
  }
  body .ProductsGroupComp {
    padding-top: 10px !important;
  }
  body
    .Homepage
    .BrandsCollection
    .sectionHeadingContainer
    .sectionHeadingMobileBold {
    margin-top: 16px !important;
  }
  body .category-banner-carousel .ant-carousel ul.slick-dots {
    bottom: 16px !important;
  }
  body .category-banner-carousel {
    margin-bottom: 10px !important;
  }
  body .subcategory-cards-container > div {
    padding: 0 !important;
  }
  body .multiusable-section-carousel .slick-track {
    gap: 10px !important;
  }
  body .ProductsCarousel > .sectionHeadingContainer {
    margin-top: 16px;
  }
  body .Homepage .ProductsCarousel .carousel_container {
    margin-top: 10px !important;
  }
  .added-footer {
    padding-bottom: 50px;
  }
  body .product-group-grid > div img {
    object-fit: cover;
  }
  .Homepage .BannerSection .sectionHeadingContainer {
    text-align: center;
  }
}

body .layout7Heading {
  text-align: left !important;
  font-size: 24px !important;
  font-family: Inter sans-serif !important;
  font-weight: 700;
}

body .layout1Heading {
  text-align: left !important;
  font-size: 24px !important;
  font-family: Inter sans-serif !important;
  font-weight: 700;
  padding-left: 10px;
}

body .sectionHeadingMobileBold {
  text-align: left !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

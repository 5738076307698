.catalogues-carousel-container {
  .carousel_container {
    max-width: 1232px !important;
    position: relative !important;
  }
  .slick-next {
    .next-btn-container {
      right: 0;
      position: absolute;
      // left: -62px;
    }
  }
  .slick-next:before {
    display: none !important;
  }

  .slick-prev {
    .prev-btn-container {
      left: 0;
      position: absolute;
      // right: -64px;
    }
  }
  .slick-prev:before {
    display: none !important;
  }
}

.skeleton-container {
  display: flex;
  gap: 8px;
}

.skeleton-box {
  flex: 1;
}

.subcategory-cards-container {
  .ant-card {
    margin: 0px !important;
    // width: 98%;
    .ant-card-body {
      display: none;
    }
  }
}

.subcategory-cards-mobile-container {
  row-gap: 10px;
  justify-content: space-evenly;
}

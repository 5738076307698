.text-center{
  text-align: center;
}
.sectionHeadingBold{
  font-size: 15px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10{
  margin-bottom: 10px;
}
.products-carousel-container {
  .slick-next {
    .next-btn-container {
      position: absolute;
      left: -28px;
    }
  }
  .slick-prev {
    .prev-btn-container {
      position: absolute;
      right: -28px;
    }
  }

  .slick-prev:before {
    display: none !important;
  }

  .slick-next:before {
    display: none !important;
  }

  .slick-track {
    display: flex !important;
    gap: 10px;
  }
}

// .BannerCarousel {
//   .carousel_container {
//     .slick-slide {
//       min-width: max-content !important;
//       width: max-content !important;
//       height: auto !important;
//     }
//     .slick-slide > div {
//       // min-width: 100% !important;
//       width: max-content !important;
//       height: auto !important;
//     }
//   }
// }
.BannerCarousel {
  margin-bottom: 5px;
  margin-top: 10px;
}
.banner_carousel {
  // img {
  //   //  width: 99%;
  //   min-height: 400px;
  // }

  .ant-image {
    width: 100%;

    .ant-image-img {
      height: auto;
    }
  }

  .slick-next {
    .next-btn-container {
      position: absolute;
      left: -15px;
    }
  }
  .slick-prev {
    .prev-btn-container {
      position: absolute;
      right: -15px;
    }
  }

  // .slick-dots li button:before {
  //   color: orange;
  // }

  .ant-carousel {
    ul.slick-dots {
      bottom: -14px !important;
      // z-index: 0px !important;
      li {
        background-color: #979797 !important;
        //background-color: red;
      }
      li.slick-active {
        button {
          background-color: #333333 !important;
        }
      }
    }
  }
}

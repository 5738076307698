// Color Pallete 🖌

@import "../../../../styles/Colors.scss";

///////////////////////////////////////////
.product_card {
  // padding: 0px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-card {
    border-radius: 0px;
    width: 100%;
    .ant-card-cover {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .outOfStock_container {
        top: 45%;
        position: absolute;
        z-index: 1;
        // top: 165px;
        // left: 0px;
        width: 100%;
        .outOfStock_tag {
          background-color: $outOfStockTagBg;
          font-size: 16px;
          color: $surface;
          width: 100%;
          display: block;
          font-family: "Inter-Regular" !important;
          height: 30px;
        }
      }

      .mobile_outOfStock_container {
        .outOfStock_tag {
          background-color: $outOfStockTagMobileBg;
          height: 20px;
          top: 45%;
        }
      }
    }
    img {
      border-radius: 0px !important;
    }
    .ant-card-body {
      padding: 10px;
      // padding: 10px 0px 10px 6px;
      background: $white;
      text-align: start;

      .ant-card-meta-title {
        margin: 0px;

        // .product_title {
        //   letter-spacing: 0px;
        //   color: #686868;
        //   opacity: 1;
        //   font-size: 13px;
        // }
        font-size: 14px;

        text-overflow: revert;
        // overflow: revert;
        white-space: revert;
        line-height: 0px;

        overflow: hidden;
        // font-weight: 600;
        margin-bottom: 5px;

        &.product_title_two_lines {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .product_title {
        display: block;
        height: 42px;
        font-size: 14px;
        color: $productTitle;
        font-family: "Inter-Regular" !important;
        color: #000;
        font-weight: 100;
      }

      .product_title_homepage {
        height: 20px;
      }
      .mobile_product_title {
        display: block;
        height: 30px;
        color: $productTitle;
      }
      .mobile_product_title_homepage {
        display: block;
        height: 18px;
        color: $productTitle;
      }

      .ant-space {
        gap: 5px !important;
        .product_price {
          // opacity: 1;
          // font-size: 12px;
          span {
            color: $black;
            font-size: 16px;
            font-family: "Inter-Regular" !important;
          }
        }

        .product_price_mega_search {
          // opacity: 1;
          // font-size: 12px;
          span {
            color: $black;
            font-size: 8px;
            font-family: "Inter-Regular" !important;
          }
        }

        .product_price_mobile {
          span {
            font-size: 12px;
            font-family: "Inter-Regular" !important;
          }
        }

        del {
          color: $secondaryTwo;
          font-size: 12px;
          // font-weight: 600;
        }

        .discount_tag {
          background-color: $discountTagBg;
          margin: 0px;
          padding: 2px;
          border: 0px;
          span {
            font-size: 12px;
            color: $discountTagColor;
            // font-weight: 600;
          }
        }
        .product_tags_container_mobile {
          display: flex;
          flex-wrap: nowrap;
          -webkit-overflow-scrolling: touch;
          padding-bottom: 2px;
          .onlineOnly_tag {
            background-color: $onlineOnlyTagBg;
            color: $secondaryTwo;
            margin: 0px;
            .ant-typography {
              font-size: 8px;
              font-family: "Inter-Regular" !important;
            }
          }
          .freeShipping_tag {
            background-color: $freeShippingTagBg;
            color: $secondaryTwo;
            margin: 0px;
            padding: 2px 5px;

            .ant-typography {
              font-size: 8px;
              font-family: "Inter-Regular" !important;
            }
          }
          .expressDelivery_tag {
            background-color: $expressDeliveryTagBg;
            color: $secondaryTwo;
            margin: 0px;
            padding-bottom: 2px;

            .ant-typography {
              font-size: 8px;
              font-family: "Inter-Regular" !important;
            }
          }
          .ant-tag {
            border: none !important;
            line-height: 18px !important;
          }
        }

        .product_tags_container {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: thin;
          scrollbar-color: var(--scrollbar-color)
            var(--scrollbar-background-color);
          padding-bottom: 5px;
          .onlineOnly_tag {
            background-color: $onlineOnlyTagBg;
            color: $secondaryTwo;
            margin: 0px;
            .ant-typography {
              font-size: 11px;
              font-family: "Inter-Regular" !important;
            }
          }
          .freeShipping_tag {
            background-color: $freeShippingTagBg;
            color: $secondaryTwo;
            margin: 0px;

            .ant-typography {
              font-size: 11px;
              font-family: "Inter-Regular" !important;
            }
          }
          .expressDelivery_tag {
            background-color: $expressDeliveryTagBg;
            color: $secondaryTwo;
            margin: 0px;
            padding-bottom: 2px;

            .ant-typography {
              font-size: 11px;
              font-family: "Inter-Regular" !important;
            }
          }
        }
      }

      // .ant-tag {
      //   border-radius: 0px;
      // }

      .mobile_discount_tag {
        // background-color: $discountTagColor !important;
        background-color: #ffeff1 !important;
        position: absolute;
        top: 3px;
        right: -5px;
        border-radius: 100px;
        .ant-typography {
          // color: $white;
          font-size: 10px;
          color: #ea462f;
          // font-weight: 600;
        }
      }
    }
  }
}

// Mz css
.product_tags_container:empty {
  display: none;
}
.product_card > a {
  height: 100%;
  display: flex;
}

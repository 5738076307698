.category-banner-carousel {
  //   .slick-track {
  //     height: 350px;
  //   }

  //   .ant-image {
  //     width: 100%;
  //   }

  .ant-carousel {
    ul.slick-dots {
      bottom: -16px !important;
      // z-index: 0px !important;
      li {
        background-color: #979797 !important;
        //background-color: red;
      }
      li.slick-active {
        button {
          background-color: #333333 !important;
        }
      }
    }
  }
}

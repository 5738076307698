// Sanaullah Color scheme

$black: black;
$white: white;
$body_color: white;
$border_color: #e2e5f1;
$default_color: #203348;
$background_color: #f1f3f2;
$collectionPage_Bg_mobile: #f4f4f6;

$SanaPrimary_color: #ffffff;
$SanaSecondary_color: #058593;
$SanaPrimaryRed_color: #ed2590;

$cyan: #ecfcf6;
$gray: #bfbfbf;
$peach_red: #ffecf9;
$primary_red: #ff0d50;
$soft_peach: #fcecec;
$yellowish_orange: #fcf5ec;
$text_light: #a1a1a1;
$outOfStock: #f8d7da;
$orange: #ff8000;
$dark_gray: #979797;
$green: #0eb36b;
$hover_color: #eee;

// Color Scheme Provided by SanaUllah

$primaryOne: #ff0d50;
$primaryTwo: #ed2590;
$primaryThree: #ff3f6c;

$secondaryOne: #333333;
$secondaryTwo: #1e1e1e;
$secondaryThree: #5a7184;
$secondaryFour: #5b5b5b;

$footerIconColor: #161d25;

$genral: #000000;
$disabled: #b7b7b7;
$background: #f2f3f7;
$border: #e2e5f1;
$surface: #ffffff;
$surfaceBorder: #e4e4e4;
$filterBorder: #f1f3f2;
$filterOptionText: #434343;
$primaryBlack: #18110c;
$primaryWhite: #ffffff;

$productTitle: #686868;
$discountedPrice: #707070;
$freeShippingTagBg: #d1ef92;
$expressDeliveryTagBg: #bf0073;
$onlineOnlyTagBg: #d8d8d8;
$outOfStockTagBg: #00000099;
$outOfStockTagMobileBg: #37011e;

$discountTagBg: #ffeff1;
$discountTagColor: #ea462f;

$breadCrumbsTextColor: #ababab;
// Alizeh Color Scheme

// $gray: #bfbfbf;
// $darkGray: #979797;
// $hoverColor: #eee;

// $surface: #ffffff;
// $genral: #000000;
// $disabled: #b7b7b7;
// //$background: #f6f5f3;
// // $border: #e7e7e7;

// $primaryText: #292929;
// $secondaryText: #6f6f6f;
